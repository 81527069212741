<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-04-03 16:49:49
-->
<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-02-11 17:26:55
-->
<template>
  <div class="contain">
    <img src="../assets/images/pay_i2.png"
         alt="" />
    <div>宝付支付中...</div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import * as pay from '@/api/pay.js'
export default {
  data() {
    return {
      code: '',
      state: '',
    }
  },
  mounted() {
    let allData = decodeURIComponent(window.location.href).split('=')
    console.log(
      '%c [ allData ]-34',
      'font-size:13px; background:#a3905c; color:#e7d4a0;',
      allData
    )
    this.code = allData[1].split('&')[0]
    this.state = allData[2].split('#')[0]
    this.getFiveCode()
  },
  methods: {
    getFiveCode() {
      pay
        .baoFuPay({
          code: this.code,
          state: this.state,
        })
        .then((res) => {
          let obj = res.data
          this.onBridgeReady(obj)
          // wx.chooseWXPay({
          //   appId: obj.appId,
          //   timeStamp: obj.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          //   nonceStr: obj.nonceStr, // 支付签名随机串，不长于 32 位
          //   package: obj.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          //   signType: obj.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          //   paySign: obj.paySign, // 支付签名
          //   success: function (res) {
          //     console.log(
          //       '%c [ res ]-48',
          //       'font-size:13px; background:#71e3dc; color:#b5ffff;',
          //       res
          //     )
          //     // 支付成功后的回调函数
          //   },
          // })
        })
    },
    onBridgeReady(data) {
      console.log('调用微信支付WeixinJSBridge')
      var vm = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            this.$message({
              message: '支付成功',
              type: 'success',
            })
          } else {
            this.$message({
              message: '支付失败',
              type: 'warning',
            })
          }
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  font-size: 16px;
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
}
</style>